@media (max-width:992px) {
    .contact-wrapper {
        width: 100%
    }

    .contact__text_item2 {
        width: 200px
    }

    .reg__form {
        width: 100%;
        margin: 85px 10% 0
    }

    .contact__social {
        width: 100%;
        margin: 35px 10%
    }
}

@media (max-width:768px) {
    .menu {
        display: none
    }

    .top-bar {
        display: block
    }

    .main-navigation {
        background: rgba(0, 0, 0, .7)
    }

    .content {
        width: 100%
    }

    #galleria {
        height: 450px
    }
}

@media (max-width:544px) {
    .logo {
        width: 200px
    }

    .logo h1 {
        font-size: 24px
    }

    .gallery__prew {
        margin: 25px 0;
    }

    .reg__form {
        width: 80%;
        margin: 85px 10% 0
    }

    .contact__social {
        width: 80%;
        margin: 35px 10%
    }

    .contact__social ul {
        margin-bottom: 50px
    }

    .top__label p {
        font-size: 11px;
        position: absolute;
        right: 33%;
        bottom: -55px;
        margin: 0;
        -webkit-transform: rotate(0);
        transform: rotate(0);
        z-index: 3
    }

    .top__label-contact p {
        bottom: -10px;
        left: 33%;
    }

    footer p {
        left: 40%;
    }
}

@media (max-width:380px) {

    .contact-wrapper {
        margin: 75px 0 0 20px
    }

    .contact__text_item2 {
        width: 260px
    }

    .top__label-contact p {
        bottom: 10px;
        left: 30%;
    }

    footer p {
        bottom: -5px;
        left: 37%;
        margin: 0;
    }
}

